<template>
  <div class="pt-3">
      <div class="dashboard__container--body" v-if="isAdmin || isOwner || isUser">
        
        <div class="dashboard__container--body--col">
          <div class="mb-3">
            <h4>Send Message to Group:</h4>
            <textarea name="updateMessage" id="updateMessage" cols="20" rows="4" v-model="message"></textarea>
          </div>
          <button class="btn btn__outlined btn__large" @click="sendMessage()">
            Send message
            <transition name="fade">
              <span class="ml-2" v-if="performingRequest2">
              <i class="fa fa-spinner fa-spin"></i>
              </span>
            </transition>
          </button>
        </div>
      </div>
      <div class="dashboard__container--body">
        <div class="dashboard__container--body--col" style="width:100%;">
          <h4>Messages</h4>

          <vue-good-table
            :columns="columns2"
            :rows="groupMessages"
            compactMode
            :pagination-options="{
              enabled: true,
              mode: 'records',
              perPage: 10,
            }"
          >
           <template slot="table-row" slot-scope="props">
              

              <span v-if="props.column.field == 'created'">
                <span>{{formatDate(props.row.created)}}</span>
              </span>

              <span v-if="props.column.field == 'message'">
                <span>{{props.row.message}}</span>
              </span>

            </template>

            
          </vue-good-table>
        </div>
      </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
// import ExportService from "@/services/ExportService"
import router from '@/router'
import firebase from 'firebase/app';
import * as moment from 'moment'
const fb = require('../../firebaseConfig.js')

export default {
  props: ['group'],
  name: 'groupMessages',
  data: () => ({
    message: '',
    showAll: true,
    performingRequest2: false,
    columns2: [
      {
        label: 'Created',
        field: 'created',
        width: '132px',
      },
      {
        label: 'message',
        field: 'message',

      },
    ],
  }),
  computed: {
    ...mapState(['currentUser', 'userProfile', 'groupUsers', 'groupMessages']),
    isOwner: function() {
      return this.group.owner == this.currentUser.uid
    },
    isAdmin() {
      return true
      // return (this.group.admins && this.group.admins.some(person => person.userId == this.currentUser.uid) || this.currentUser.uid === 'yrD4w9VWq4ZxJUlzcWfXlSNi6Ri2' || this.currentUser.uid === 'DN9vVu3qDhPdpBOUpIb8GopOuhY2')
    },
    isUser() {
      return this.group.users && this.group.users.some(person => person.userId == this.currentUser.uid)
    },
    realUsers() {
      return this.groupUsers.filter(user => {
        return user.id
      })
    }
  },
  components: {
    Loader,
  },
  created () {
    if (!this.groupUsers || this.groupUsers.length < 1) {
      this.$store.dispatch("getGroupUsers", this.$route.params.id);
    }
    if (!this.groupMessages || this.groupMessages.length < 1) {
      this.$store.dispatch("getGroupMessages", this.$route.params.id);
    }
  },
  methods: {
    formatDate(q) {
      if(q) {
        const postedDate = new Date(q.seconds) * 1000;
        return moment(postedDate).format('MMMM Do YYYY')
      } else {
        return null
      }
    },
    async sendMessage () {
      this.performingRequest2 = true
      let message = this.message
      let theMessage = {
        message: this.message,
        groupId: this.group.id,
      }
      console.log(message)
      await this.realUsers.forEach(user => {
        console.log(user)
        if (user.phone) {
          console.log("if")
          let newMessage = {
            user: user,
            message: message,
          }
          console.log(newMessage)
          var newGroupUpdate = firebase.functions().httpsCallable('newGroupUpdate');
          newGroupUpdate({
            user: user,
            message: message
          })
        } else {
          console.log("else")
        }
      })
      fb.groupUpdatesCollection.add(theMessage)
      .then(
        doc => {
          fb.groupUpdatesCollection.doc(doc.id).update({
            id: doc.id,
            created: fb.firestore.FieldValue.serverTimestamp(),
          })
        }
      )
      setTimeout(() => {
          this.performingRequest2 = false,
          this.message = ''
          this.$store.dispatch("getGroupMessages", this.$route.params.id);
      }, 3000)
    },
    goBack() {
      router.go(-1)
    },
  },
}
</script>